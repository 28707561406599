<template>
    <div>
        <vx-card>
            <div slot="no-body" class="tabs-container px-6 pt-6">
                <div class="vx-row">
                    <h3 class="ml-2">Thông tin tài sản</h3>
                </div>
                <div class="vx-row">
                    <div class="vx-col md:w-1/4 w-full mt-2">
                        <label class="vs-input--label">Chi nhánh</label>
                        <v-select class="" v-model="branchModel" :clearable="false" :options="branchOptions"
                                  data-vv-validate-on="blur" :disabled="isUpdate" v-validate="'required'"
                                  name="branch">
                                  <span slot="no-options">{{$t('menuOrganization.emptyList')}}</span>
                        </v-select>
                        <span class="text-danger text-sm">{{ errors.first('branch') }}</span>
                    </div>
                </div>
                <!-- <div class=" mt-5 vx-col" v-if = "!isUpdate">
                  <feather-icon icon="PlusIcon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="addNewRecord(0)"/>
                </div> -->

                <div class="vx-row mt-3">
                    <div style="width: 45% !important;" class="vx-col lg:w-1/3 md:w-1/2 w-1/2">
                        <label class="vs-input--label">Tài sản</label>
                        <v-select :value="assignDevice.deviceString" :disabled="isUpdate" :clearable="false"
                                  :options="deviceOptions"
                                  @input="device => updateDevice(assignDevice, device)" v-validate="'required'">
                                  <span slot="no-options">{{$t('menuOrganization.emptyList')}}</span>
                        </v-select>
                    </div>
                </div>
                <div class="vx-row mt-3">
                    <div class="vx-col lg:w-1/3 md:w-1/2 w-1/2">
                        <label class="vs-input--label">Nhân viên</label>
                        <v-select :value="assignDevice.employeeString" :disabled="isUpdate" :clearable="false"
                                  :options="employeeOptions"
                                  @input="employee => updateEmployee(assignDevice, employee)" v-validate="'required'">
                                  <span slot="no-options">{{$t('menuOrganization.emptyList')}}</span>
                        </v-select>
                    </div>
                    <div class="vx-col lg:w-1/3 md:w-1/2 w-1/2">
                        <vs-input class="w-full" label="Lý do" v-model="assignDevice.reason"/>
                    </div>
                </div>
                <div class="vx-row mt-3">
                    <div class="vx-col md:w-1/3 w-full">
                        <label class="vs-input--label">Ngày cấp</label>
                        <datepicker :format="'dd/MM/yyyy'" v-model="assignDevice.assignDate"
                                    data-vv-validate-on="input" v-validate="'required'" name="assignDate"></datepicker>
                        <span class="text-danger text-sm">{{ errors.first('assignDate') }}</span>
                    </div>
                    <div class="vx-col lg:w-1/3 md:w-1/2 w-1/2">
                        <vs-input class="w-full" label="Người cấp" v-model="assignDevice.assignBy"/>
                    </div>
                </div>
                <div class="vx-row mt-3">
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <vs-textarea class="w-full" label="Thông tin bổ sung" v-model="assignDevice.metadata"
                                     name="metadata"/>
                        <span class="text-danger text-sm">{{ errors.first('metadata') }}</span>
                    </div>
                </div>


                <div class="vx-row">
                    <div class="vx-col w-full">
                        <div class="mt-8 mb-5 flex flex-wrap items-center justify-end">
                            <vs-button class="ml-auto mt-2" @click="saveChanges"
                                       :disabled="!validateForm || !employeeOptions.length || !deviceOptions.length">{{
                                    `${isUpdate ?
                                        'Cập nhật' : 'Cấp phát'}`
                                }}
                            </vs-button>
                            <vs-button class="ml-4 mt-2" type="border" color="warning" @click="deviceList">Quay lại
                            </vs-button>
                        </div>
                    </div>
                </div>
            </div>
        </vx-card>
    </div>
</template>

<script>

import vSelect from 'vue-select'
import province from '@/assets/utils/province.js';
import Datepicker from 'vuejs-datepicker';
import utils from "../../../components/utils";

export default {
    components: {
        vSelect,
        Datepicker
    },
    data() {
        return {
            user_data: null,
            activeTab: 0,
            isUpdate: false,
            isUpdateFace: false,
            branchOptions: [],
            deviceOptions: [],
            deviceDatas: [],
            employeeOptions: [],
            employeeDatas: [],
            assignDevice: {},
            selectedOrganizationBranchValue: null
        }
    },
    created() {
        let timeDuration = 30, timeSpans = [];
        for (let i = 0; i < 24 * 60; i = i + timeDuration) {
            timeSpans.push({value: i, label: utils.minuteToHour(i)});
        }
        this.timeSpans = timeSpans;
        let branchList = JSON.parse(localStorage.getItem('branchInfoList') || '[]');
        this.branchOptions = branchList.map(x => {
            return {label: x.organizationBranchName, value: x.id}
        });
        this.selectedOrganizationBranchValue = this.$route.query.branch_id;

        Promise.all([this.loadEmployee(), this.loadDevice()]).then(() => {
            this.addNewRecord()
        }).catch(function (err) {
        })
    },
    computed: {
        branchModel: {
            get() {
                return {
                    label: this.branchOptions.find(x => x.value == this.selectedOrganizationBranchValue).label,
                    value: this.selectedOrganizationBranchValue
                }
            },
            set(obj) {
                this.selectedOrganizationBranchValue = obj.value;
                Promise.all([this.loadEmployee(), this.loadDevice()]).then(() => {
                }).catch(function (err) {
                })
            }
        },
        validateForm() {
            return !this.errors.any()
        },
    },
    methods: {
        getLabelString(object) {
            return (object && object.label) || 'Chưa xác định';
        },
        loadEmployee() {
            return new Promise((resolve, reject) => {
                if (!this.selectedOrganizationBranchValue) return reject();
                this.$vs.loading();
                this.$crm.post('/employee/find-by-organization-branch/' + this.selectedOrganizationBranchValue).then((response) => {
                    this.employeeData = response.data;
                    this.$vs.loading.close();
                    if (response.data.length == 0) {
                        reject()
                        this.employeeOptions = []
                        return this.$vs.notify({
                            title: 'Error',
                            text: 'Không tồn tại nhân viên nào',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        });
                    }
                    this.employeeOptions = response.data.map(item => {
                        return {
                            value: item.employeeId,
                            label: item.employeeCode + " " + item.fullName
                        }
                    });
                    resolve()
                }).catch((err) => {
                    this.$vs.loading.close();
                    reject()
                    return this.$vs.notify({
                        title: 'Error',
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
            })
        },
        loadDevice() {
            return new Promise((resolve, reject) => {
                if (!this.selectedOrganizationBranchValue) return reject();
                this.$vs.loading();
                this.$crm.post('/device/find-by-condition', {
                    organizationBranchId: this.selectedOrganizationBranchValue,
                    deviceType: 0
                }).then((response) => {
                    this.$vs.loading.close();
                    if (response.data.length == 0) {
                        this.deviceOptions = []
                        return this.$vs.notify({
                            title: 'Error',
                            text: 'Không tồn tại thiết bị nào',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        });
                    }
                    this.deviceOptions = response.data.reduce((arr, item) => {
                        if (!this.isUpdate && this.remainQuantity == 0) return arr;
                        arr.push({
                            value: item.id,
                            label: item.type + " " + item.brand + " " + item.model + (item.serialNumber ? " - " + item.serialNumber : "")
                        })
                        return arr;
                    }, []);
                    this.deviceDatas = response.data;
                    this.$vs.loading.close();
                    resolve()
                }).catch((err) => {
                    this.$vs.loading.close();
                    reject()
                    return this.$vs.notify({
                        title: 'Error',
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
            })

        },
        updateEmployee(setting, employee) {
            setting.employeeString = employee.label;
            setting.employeeId = employee.value;
        },
        updateDevice(setting, device) {
            setting.deviceString = device.label;
            setting.deviceId = device.value;
        },
        async saveChanges(evt) {
            evt.preventDefault();
            try {
                if (!this.validateForm) return;

                const result = await this.$validator.validateAll();
                if (!result) return;
                let url = '/device/assign', data = [this.assignDevice];
                if (this.isUpdate) {
                    url = '/device/update-assigned-device/' + this.$route.query.id;
                    data = this.assignDevice
                }
                this.$vs.loading();
                this.$crm.post(url, data).then(() => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        color: 'success',
                        text: this.isUpdate ? 'Cập nhật thành công' : 'Cấp phát thiết bị thành công',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                    });
                    this.$router.push(`/user/manage-device${this.selectedOrganizationBranchValue ? `?branch_id=${this.selectedOrganizationBranchValue}` : ''}`).catch(() => {
                    })
                }).catch((err) => {
                    this.$vs.loading.close();
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
            } catch (ex) {
                console.log(ex);
            }
        },
        addNewRecord(index) {
            let id = this.$route.query.id;
            if (id) {
                this.$crm.post('/device/find-assigned-device-by-id/' + id).then((response) => {
                    if (response.data && response.data.length) {
                        this.isUpdate = true;
                        this.assignDevice = response.data[0]
                    }
                    if (!this.isUpdate) {

                    }
                    let employee = this.employeeOptions.find(item => item.value == this.assignDevice.employeeId);
                    this.updateEmployee(this.assignDevice, employee || this.employeeOptions[0]);
                    let device;
                    if (this.$route.query.device_id) {
                        device = this.deviceOptions.find(item => item.value == this.$route.query.device_id);
                    }
                    this.updateDevice(this.assignDevice, device || this.deviceOptions[0])
                    this.$vs.loading.close();

                }).catch((err) => {
                    this.$vs.loading.close();
                    return this.$vs.notify({
                        title: 'Error',
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
            } else {
                this.assignDevice = {
                    quantity: 1,
                }
                this.updateEmployee(this.assignDevice, this.employeeOptions[0]);
                let device;
                if (this.$route.query.device_id) {
                    device = this.deviceOptions.find(item => item.value == this.$route.query.device_id);
                }
                this.updateDevice(this.assignDevice, device || this.deviceOptions[0])
            }
        },
        deviceList() {
            this.$router.push(`/user/manage-device${this.selectedOrganizationBranchValue ? `?branch_id=${this.selectedOrganizationBranchValue}` : ''}`).catch(() => {
            })
        },
        deleteRecord(index) {
            this.assignDevice.splice(index, 1)
        }
    },
}
</script>

<style scoped>

</style>
